import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from 'react-redux';
import {useSearch, useFilterItemTypes, useGenderFormatMessage, useAllRecommendations} from '../../hooks';

import { getQueryStringParams, setUrlParams, isHomePage } from '../../utils/urls';
import { isObjectEmpty } from '../../utils/objects';
import { setSearchBy } from '../../utils/search';
import { setListItems, setDimensions } from '../../state/items';
import { callWithCache } from '../../utils/callWithCache';
import { useDeviceData } from 'cet-components-lib/dist/hooks';

export const useLists = (isCatalog, isSearch) => {
  const { isMobileView } = useDeviceData();
  const intl = useIntl();
  const dispatchSearch = useSearch();
  const filterItemTypes = useFilterItemTypes();
  const { push, location } = useHistory();
  const { search, pathname } = location;
  const dispatch = useDispatch();
  const isReady = useRef(false);
  const prevPathname = useRef('');
  const [wasSearchCalled, setWasSearchCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isAdvancedFiltersEnabled, setIsAdvancedFiltersEnabled] = useState(false);
  const [disciplinesAndAgeGrades, setDisciplinesAndAgeGrades] = useState(null);
  const [ageGradesAndDisciplines, setAgeGradesAndDisciplines] = useState(null);
  const { getGenderFormatMessage } = useGenderFormatMessage();
  const recommendations = useAllRecommendations();

  const { globalItems, allDimensions, isLoggedIn, sectors } = useSelector(({ globalItems, items, user, dimensions, sectors }) => ({
    globalItems,
    allDimensions: dimensions.dimensions,
    isLoggedIn: !!Object.keys(user.info).length,
    sectors: sectors.sectors
  }));

  const filters = getQueryStringParams();

  const isTeacherCenterKesem = useMemo(() => filters?.teachersCenter?.toLowerCase() === 'kesem', [filters?.teachersCenter]);

  const loadBasicSwimlanes = useCallback(async () => {
    setIsLoading(true);

    dispatch(setListItems([globalItems.newestItems, globalItems.mostPopularItems]));
    isReady.current = true;

    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, [dispatch, globalItems.mostPopularItems, globalItems.newestItems]);

  const clearUndefinedFilters = useCallback(filters => {
    const cleared = { ...filters };
    const undefinedFilters = Object.keys(filters).filter(key => !filters[key]);
    undefinedFilters.forEach(key => {
      delete cleared[key];
    });
    return cleared;
  }, []);

  const mapRecordsDimensions = (records) => records
    .filter(record => record.stats)
    .map(({ itemTypes, title, totalCount }) => ({
      value: itemTypes,
      text: title,
      totalCount
  }));

  const getRecommendations = useCallback(
    filters => {
      const { ageGrades, disciplines } = filters;
      if (recommendations.hasOwnProperty(ageGrades) && recommendations[ageGrades].hasOwnProperty(disciplines)) {
          return recommendations[ageGrades][disciplines];
      }
      return null;
    },
    [recommendations]
  );

  const fetchDimensions = useCallback(
    (records) => {

      const recordsDimensions = mapRecordsDimensions(records);
      const recommendations = getRecommendations(filters);

      if (isCatalog && recommendations && !!recordsDimensions.length && recommendations) {
        recordsDimensions.unshift({
          value: 'recommendations',
          id: 'recommendations',
          text: getGenderFormatMessage('content_recommendations'),
          label: getGenderFormatMessage('content_recommendations'),
          totalCount: recommendations.length
        });
      }

      dispatch(setDimensions(recordsDimensions))

    },
    [search]
  );

  const isFiltered = useMemo(() => {
    return Object.keys(filters).filter(key => key !== 'allFields' && key !== 'ageGrades' && key !== 'disciplines' && key !== 'itemTypes' && key !== 'exactSearch' && key !== 'teachersCenter' && key !== 'activityTypes').length > 0;
  }, [filters]);

  const getRecords = useCallback(
    async () => {
      const itemTypes = filters?.itemTypes;
      let groupName = filters?.groupName || 'catalog';

      let searchBy = setSearchBy(filters);
      let statsType = 1;

      if (!itemTypes || itemTypes === 'all') {
          if (isCatalog) {
            statsType = 2;
          }
        const catalogItemTypes = allDimensions.itemTypes.filter(item => item.parentId === groupName).map(item => item.id);
        const itemTypesIndex = searchBy.findIndex(i => i.itemTypes);
        if (itemTypesIndex >= 0) {
          searchBy[itemTypesIndex].itemTypes = catalogItemTypes;
        } else {
          searchBy.push({itemTypes: catalogItemTypes});
        }
      }

      const maxResults =
        filters.hasOwnProperty('allFields') || filters.hasOwnProperty('itemTypes') || filters.hasOwnProperty('ageGrades') || filters.hasOwnProperty('disciplines')
          ? 200 //maxResultsItems
          : 10;

      if (isCatalog || isSearch) {
        if (isTeacherCenterKesem) {
          searchBy.push({ activityTypes: ['kesem'] });
        } else {
          const unwantedActivityTypes = ['selTime', 'teachingPractices', 'teachingSkills', 'shvilim'];
          const filteredActivityTypes = allDimensions.activityTypes
          .filter(type => !unwantedActivityTypes.includes(type.id))
          .map(type => type.id);
          searchBy.push({ activityTypes: filteredActivityTypes });
        }
      }
      
      const records = await dispatchSearch(searchBy, 'itemTypes', maxResults, null,true, 'filter', true, statsType);

      return records;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search, dispatchSearch]
  );

  const fetchSearch = useCallback(
    async () => {
      const isFetchDimensions = !filters.hasOwnProperty('itemTypes') || filters.itemTypes === 'all';

      setWasSearchCalled(false);
      setIsLoading(true);

      if (
        filters.allFields &&
        (filters.exactSearch === 1 || filters.exactSearch === '1') &&
        !(filters.allFields[0] === '"' && filters.allFields[filters.allFields.length - 1] === '"')
      ) {
        filters.allFields = `"${filters.allFields}"`;
      }

      if(!isNaN(filters.allFields)) {
          filters.allFields = filters.allFields.toString();
      }

      let records;
      let dimensions = []
      // let filters = clearUndefinedFilters(filters);
      if (filters.itemTypes !== 'recommendations') {
        // Fetch records from contentApi/Search
        records = await getRecords();
        dimensions = records.filter(record => record.stats);
        records = records.filter(record => record?.data?.length > 0);
      } else {
        // get recommendations from session storage
        const data = recommendations[filters.ageGrades][filters.disciplines];
        records = [
          {
            data,
            itemTypes: 'recommendations',
            title: getGenderFormatMessage('content_recommendations'),
            totalCount: data.length
          }
        ];
      }

      dispatch(setListItems(records));
      isFetchDimensions && dimensions && fetchDimensions(dimensions);

      if (!isObjectEmpty(filters)) {
        setWasSearchCalled(true);
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    },
    [search]
  );

  const onItemTypeChange = useCallback(
    (prevFilters, value) => {

      const filters = { ...prevFilters, itemTypes: value };

      setIsFiltersOpen(false);

      if (value === 'book' || value === 'course') {
        const basicFilters = ['ageGrades', 'disciplines', 'syllabusSubjects', 'syllabusSubsubjects', 'allFields', 'itemTypes', 'exactSearch', 'teachersCenter', 'activityTypes'];
        const filtersToRemove = Object.keys(filters).filter(key => !basicFilters.includes(key));

        filtersToRemove.forEach(key => {
          delete filters[key];
        });
        filters.groupName = value + 's';
      } else {
        filters.groupName = 'catalog';
      }

      if (prevFilters.itemTypes !== filters.itemTypes) {
        const historyState = setUrlParams(filters);
        push(historyState);
      }

    },
    [push]
  );

  const loadDisciplinesAndAgeGrades = useCallback(async sectors => {

    var result = null;
    await callWithCache(window.cet.microservices.contentapi.content.getDisciplinesByAgeGrades, 'getDisciplinesByAgeGrades', sectors || [])
      .then(data => {
        result = data;
      })
      .catch(error => {
        console.error(error);
      });

    return result;
  }, []);

  const onFilterChange = useCallback(
    (prevFilters, filters) => {
      const keys = Object.keys(filters);
      const needRefresh =
        keys.find(key => {
          if (filters[key] !== prevFilters[key]) {
            if ((prevFilters[key] === 'all' && !filters[key]) || (!prevFilters[key] && filters[key] === 'all')) {
              return false;
            }

            return true;
          }

          return false;
        }) || keys.length !== Object.keys(prevFilters).length;

      if (needRefresh) {
        const historyState = setUrlParams(filters);

        push(historyState);
      }
    },
    [push]
  );

  const onToggleFilters = useCallback(() => {
    setIsFiltersOpen(isFiltersOpen => !isFiltersOpen);
  }, []);

  // scroll to top
  useEffect(() => {
    if (!isMobileView) {
      document.querySelector('#rootScroll').scrollTo(0, 0);
    }
    setWasSearchCalled(false);
  }, [isMobileView, search]);

  useEffect(() => {
    let isActive = true;

    async function fetchData(sectors) {
      let response = await loadDisciplinesAndAgeGrades(sectors);
      if (isActive && response) {
        setDisciplinesAndAgeGrades(response);
        const inverse = {};
        Object.entries(response).map(item => item[1].map(discipline => {
          if (!inverse[discipline.id]) {
            inverse[discipline.id] = [];
          }
          inverse[discipline.id].push(item[0]);
        }))
        setAgeGradesAndDisciplines(inverse);
      }
    }

    setTimeout(() => {
      if (isActive) {
        fetchData(sectors);
      }
    }, 10);

    return () => {
      isActive = false;
    };
  }, [loadDisciplinesAndAgeGrades, sectors]);


  useEffect(() => {
    const filterItemTypesKeys = Object.keys(filterItemTypes);
    if (filterItemTypesKeys.length > 0) {
      if (!filters.itemTypes) {
        setIsAdvancedFiltersEnabled(false);
      } else {
        const filter = filterItemTypesKeys.find(key => {
          return filterItemTypes[key]?.includes(filters.itemTypes);
        });

        setIsAdvancedFiltersEnabled(!!filter);
      }
    }
  }, [filters.itemTypes, filterItemTypes, dispatch]);

  useEffect(() => {
    if (isReady.current) {
      const queryParams = getQueryStringParams();

      if (isCatalog && (!queryParams.ageGrades || !queryParams.disciplines)) {
        loadBasicSwimlanes();

      }
    }
  }, [loadBasicSwimlanes]);

  // Fetch search according to query params
  useEffect(() => {
    const pathName = window.location.pathname;

    if (search && !wasSearchCalled) {
      // item page on first load
      if (pathName.toLocaleLowerCase().includes('/item/') && !prevPathname.current) {
        setIsFiltersOpen(false);
      }

      fetchSearch();
    }
    else if (isHomePage(intl.locale) ||  (isCatalog && !search)) {
      setIsLoading(false);
    }
  }, [ pathname, search, wasSearchCalled]);

  //This will work as long is this the last useEffect
  useEffect(() => {
    prevPathname.current = pathname;
  }, [pathname]);

  return {
    onItemTypeChange,
    onFilterChange,
    onToggleFilters,
    isAdvancedFiltersEnabled,
    isFiltersOpen,
    isLoading,
    isFiltered,
    wasSearchCalled,
    disciplinesAndAgeGrades,
    ageGradesAndDisciplines
  };
};
